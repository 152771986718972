// import { LOGIN_ACTIONS, SAGA_GET_REQUEST_API, SAGA_RECEIVE_DATA, SAGA_GET_COMMENTS_BY_ID, SAGA_GET_RECEIVE_COMMENTS_BY_ID } from "../../constants/actions";
import { defaultValuesRegister } from "../../constants/constants";
import { REGISTER_ACTIONS } from "../../constants/actions";

let defaultState = defaultValuesRegister;

const register = (state = defaultState, action) => {
  switch (action.type) {
    case REGISTER_ACTIONS.REGISTER_ACTIONS_RECEIVE_RESPONSE_EMAIL_BY_CODE:

      return {
        ...state,
        responseValidateByCode: action.response
      }
    case REGISTER_ACTIONS.REGISTER_ACTIONS_GET_EMAIL_BY_CODE:
      return {
        ...state,
        isLoaded: true
      }
      case REGISTER_ACTIONS.REGISTER_ACTIONS_HANDLE_USER_INFO: {
        const aux = { ...state.user };
  
        aux[action.id] = action.value;
  
        return {
          ...state,
          user: aux
        }
      }
      case REGISTER_ACTIONS.REGISTER_ACTIONS_REGISTER_USER: {
        return {
          ...state,
          isloading: true
        }
      }
      case REGISTER_ACTIONS.REGISTER_ACTIONS_RECEIVE_RESPONSE: {
        return {
          ...state,
          userRegisteredResponse: action.response,
          isloading: false
        }
      }

      case REGISTER_ACTIONS.REGISTER_ACTIONS_RESET:
        return {
          ...state,
          userRegisteredResponse: {}
        }

    default:
      return state
  }
}

export default register;